@import "./assets/scss/Breakpoints.module";
@import "./assets/scss/Colors.module";

.navbar.bg-dark {
  background-color: $primary-700 !important;
}

.page-dom {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}


.page_wrapper {
  display: flex;
}

.page_layout {
  width: 100%;

  @media screen and (min-width: $large) {
    margin-left: 250px;
  }
}

.container {
  margin: 24px 18px;

  @media screen and (min-width: $large) {
    margin: 30px 34px;
  }
}

.table__search {
  margin-bottom: 10px;
  border-radius: 0;
  border: none;

  &__wrapper {
    background: #E9ECEF;
  }
}
.DayPickerInput-OverlayWrapper {
  z-index: 99999;
  background: $white;
}
