@import "../../assets/scss/Breakpoints.module";
@import "../../assets/scss/Colors.module";

.navbar {
  border-bottom: 1px solid $primary-200;

  &__toggler {
    &:focus, &:active {
      box-shadow: none;
    }
  }

  &__item {
    padding-left: 10px;

    &:hover {
      background: $primary-100;
    }
  }

  &__link {
    color: $font;
    padding: 10px 20px;

    &:hover, &:active, &:focus {
      color: $font-hover;
    }
  }

  &__link_active {
    color: $primary-500;

    &:hover, &:active, &:focus {
      background: $primary-100;
      color: $primary-500;
    }
  }

  @media screen and (min-width: $large) {
    width: 0;
    display: none;
  }
}
