.skeleton {
  display: flex;
  background-color: #eee;
  background-image: linear-gradient(
                  90deg,
                  #eee,
                  #f5f5f5,
                  #eee
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  line-height: 1;
  width: 100%;
  animation: skeletonRunner 1s ease-in-out infinite;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}

@keyframes skeletonRunner {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}
