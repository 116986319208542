@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600&family=Source+Sans+Pro:wght@400;500&display=swap');
@import "./assets/scss/Colors.module.scss";

:root {
    --bs-emphasis-color-rgb: 68, 89, 165;
}

html, body, .App, #root {
    height: 100%;
    width: 100%;
    background: #ffffff;
    color: #333333;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    color: #111;
}

.form-label {
    font-weight: bold;
    color: #444;
}

.form-control, .form-select {
    font-size: .9rem;
    padding: .75rem 1rem;
}

.card {
    border: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.btn.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #4459a5;
    --bs-btn-border-color: #4459a5;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #26325d;
    --bs-btn-hover-border-color: #26325d;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #26325d;
    --bs-btn-active-border-color: #4459a5;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #4459a5;
    --bs-btn-disabled-border-color: #4459a5;
}

.btn.btn-outline-primary {
    --bs-btn-color: #4459a5;
    --bs-btn-border-color: #4459a5;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #4459a5;
    --bs-btn-hover-border-color: #4459a5;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4459a5;
    --bs-btn-active-border-color: #4459a5;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #4459a5;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #4459a5;
    --bs-gradient: none;
}

.btn-success {
    --bs-btn-color: #fff;
    --bs-btn-bg: #449d44;
    --bs-btn-border-color: #449d44;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #255625;
    --bs-btn-hover-border-color: #255625;
    --bs-btn-focus-shadow-rgb: 60, 153, 110;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #255625;
    --bs-btn-active-border-color: #255625;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #a3d7a3;
    --bs-btn-disabled-border-color: #a3d7a3;
}

.navbar.bg-dark {
    background-color: #26325d !important;
}

.page-dom {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.text-primary {
    color: #4459a5 !important;
}

.separator-v {
    border: 1px solid #cccccc;
    height: 100%;
    margin: 0 4px;
    min-height: 42px;
}

.pointer {
    cursor: pointer;
}

.nav-pills {
    --bs-nav-pills-border-radius: var(--bs-border-radius);
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #4459a5;
}

.nav-link {
    color: #4459a5;
}

a {
    color: #4459a5;
    text-decoration: none;
    font-weight: 600;
    border-radius: 4px;
    padding: 4px 6px;
}

.form-check-input:checked {
    background-color: #4459a5;
    border-color: #4459a5;
}

.form-check-input:focus {
    border-color: #4459a5;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(68, 89, 165, 0.35);
}

.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%234459a5%27/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

.form-control:focus, .form-select:focus {
    border-color: #4459a5;
    box-shadow: 0 0 0 .25rem rgba(68, 89, 165, 0.25);
}
