@import "../../../assets/scss/Colors.module";

.prefix {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 12px;
  color: $font;
  background: $primary-100;

  &_active {
    background: $primary-500;
    color: $white;
  }
}
