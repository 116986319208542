@import "../../assets/scss/Breakpoints.module";
@import "../../assets/scss/Colors.module";

.sidebar {
  width: 250px;
  height: 100vh;
  border-right: 1px solid $primary-200;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: space-between;
  position: fixed;

  &__logo {
    padding: 20px;
    max-width: 125px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  &__menu {
    height: calc(100vh - 225px);
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(0, 0, 0, .5);
      box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }

    &_item {
      padding: 12px 20px;
      color: $font;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-decoration: none;
      text-underline: none;

      &__icon {
        padding-right: 10px;
      }

      &__title {
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        background: $primary-100;
        color: $font-hover;
      }
    }

    &_item__active {
      position: relative;
      color: $primary-500;
      background: $primary-100;
      font-weight: 700;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        height: 40px;
        width: 5px;
        background: $primary-500;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:hover {
        background: $primary-100;
        color: $primary-500;
      }
    }
  }

  &__top {

  }

  &__footer {
    box-shadow: rgba(0, 0, 0, 0.45) 0px -15px 25px -20px;
  }

  @media screen and (max-width: $large) {
    width: 0;
    display: none;
  }
}
