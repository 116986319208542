@import "../../assets/scss/Colors.module";

.state {
  &_offline {
    position: fixed;
    text-align: center;
    padding: 8px;
    background: $danger-500;
    color: $white;
    z-index: 99999;
    width: 100%;
  }
}
