$white: #ffffff;
$black: #000000;

$font: #222222;
$font-light: #888888;
$font-hover: #111111;
$background: #fcfcfc;

$primary-100: #eff1f9;
$primary-200: #cbd2ea;
$primary-300: #a7b2db;
$primary-400: #8393cc;
$primary-500: #4459a5;
$primary-600: #354681;
$primary-700: #26325d;
$primary-800: #171f39;
$primary-900: #080b15;

$tertiary-100: #f8f8f8;
$tertiary-200: #dedede;
$tertiary-300: #c4c4c4;
$tertiary-400: #ababab;
$tertiary-500: #787878;
$tertiary-600: #5f5f5f;
$tertiary-700: #454545;
$tertiary-800: #2c2c2c;
$tertiary-900: #121212;

$danger-100: #ffffff;
$danger-200: #fae3e5;
$danger-300: #f3b7bd;
$danger-400: #eb8c95;
$danger-500: #dc3545;
$danger-600: #bd2130;
$danger-700: #921925;
$danger-800: #66121a;
$danger-900: #3b0a0f;

$success-100: #ffffff;
$success-200: #eaf6ea;
$success-300: #c7e6c7;
$success-400: #a3d7a3;
$success-500: #5cb85c;
$success-600: #449d44;
$success-700: #357935;
$success-800: #255625;
$success-900: #163216;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$border: #CCCCCC;
